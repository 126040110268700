var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-countdown", {
    attrs: { time: +_vm.time * 1000, transform: _vm.transform },
    on: {
      end: function ($event) {
        return _vm.$emit("end")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ days, hours, minutes, seconds }) {
          return [
            _c("div", { staticClass: "staking-timer" }, [
              _c("div", { staticClass: "staking-timer__item" }, [
                _c("div", { staticClass: "staking-timer__number" }, [
                  _vm._v(_vm._s(days)),
                ]),
                _c("div", { staticClass: "staking-timer__type" }, [
                  _vm._v("days"),
                ]),
              ]),
              _c("div", { staticClass: "staking-timer-separator" }, [
                _vm._v(":"),
              ]),
              _c("div", { staticClass: "staking-timer__item" }, [
                _c("div", { staticClass: "staking-timer__number" }, [
                  _vm._v(_vm._s(hours)),
                ]),
                _c("div", { staticClass: "staking-timer__type" }, [
                  _vm._v("hrs"),
                ]),
              ]),
              _c("div", { staticClass: "staking-timer-separator" }, [
                _vm._v(":"),
              ]),
              _c("div", { staticClass: "staking-timer__item" }, [
                _c("div", { staticClass: "staking-timer__number" }, [
                  _vm._v(_vm._s(minutes)),
                ]),
                _c("div", { staticClass: "staking-timer__type" }, [
                  _vm._v("min"),
                ]),
              ]),
              _c("div", { staticClass: "staking-timer-separator" }, [
                _vm._v(":"),
              ]),
              _c("div", { staticClass: "staking-timer__item" }, [
                _c("div", { staticClass: "staking-timer__number" }, [
                  _vm._v(_vm._s(seconds)),
                ]),
                _c("div", { staticClass: "staking-timer__type" }, [
                  _vm._v("sec"),
                ]),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }