<template>
  <div
      :class="`progress-bar--level-${progressLevel}`"
      class="progress-bar"
  >
      <div
          v-for="i in 5"
          :key="i"
          class="progress-bar__section"
      >
          <div
              v-for="j in 4"
              :key="j"
              :class="[
                {'progress-bar-block--filled': getFilledClass(i, j) },
                {'progress-bar-block--rounded': isAnimated && getRoundedClass(i, j) },
              ]"
              class="progress-bar-block"
          />
      </div>
  </div>
</template>

<script>
export default {
    name: "ProgressBarBlocks",
    props: {
        count: { type: [String, Number], default: 0 },
        additionalBlocks: { type: [Number], default: 0 },
    },
    computed: {
        progressLevel() {
            return Math.floor(this.count / 20);
        },
        filledBlocks() {
            return this.count - this.progressLevel * 20;
        },
        roundedBlocks() {
            return this.filledBlocks + this.additionalBlocks;
        },
    },
    watch: {
        additionalBlocks() {
            this.isAnimated = false;

            requestAnimationFrame(() => this.isAnimated = true);
        },
    },
    data(){
        return {
            isAnimated: true,
        }
    },
    methods: {
        getFilledClass(i, j) {
            return (i - 1) * 4 + j <= this.filledBlocks;
        },
        getRoundedClass(i, j) {
            const index = (i - 1) * 4 + j;

            return index > this.filledBlocks &&
                   index <= this.roundedBlocks;
        }
    },
}
</script>

<style lang="scss" scoped>

.progress-bar {
    flex-grow: 1;
    height: 16px;
    display: flex;
    gap: 0.5em;
    color: #6B7B98;

    &--level-0 {
        color: #06BE90;
    }
    &--level-1 {
        color: #0962be;
    }
    &--level-2 {
        color: #be2abe;
    }
    &--level-3 {
        color: #ec7600;
    }
    &--level-4 {
        color: #d60035;
    }

    &-block {
        overflow: hidden;
        width: 100%;
        height: 100%;
        transform: skewX(-4deg);
        border-radius: 1px;
        border: 1px solid inherit;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: currentColor;
            opacity: 0.35;
        }

        &--filled {
            background-color: currentColor;

            &:after {
                display: none;
            }
        }

        &--rounded {
            background: transparent;
            animation: blink 1s infinite;
            border: 1px solid currentColor;
        }
    }
}

@keyframes blink {
    0% {
        box-shadow: inset 0 0 5px 0px currentColor;
    }
    50% {
        box-shadow: inset 0 0 0px 0px currentColor;
    }
    100% {
        box-shadow: inset 0 0 5px 0px currentColor;
    }
}

.progress-bar__section {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 0.22em;
}

@media screen and (max-width: $bp_mb_xs) {
    .progress-bar {
        height: 11px;
        gap: 0.35em;

        &__section {
            gap: 0.11em;
        }
    }
}
</style>
