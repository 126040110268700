var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "progress-bar",
      class: `progress-bar--level-${_vm.progressLevel}`,
    },
    _vm._l(5, function (i) {
      return _c(
        "div",
        { key: i, staticClass: "progress-bar__section" },
        _vm._l(4, function (j) {
          return _c("div", {
            key: j,
            staticClass: "progress-bar-block",
            class: [
              { "progress-bar-block--filled": _vm.getFilledClass(i, j) },
              {
                "progress-bar-block--rounded":
                  _vm.isAnimated && _vm.getRoundedClass(i, j),
              },
            ],
          })
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }