<template>
    <div
        class="skill"
        :class="{'skill--active': active}"
        @click="$emit('click')"
    >
        <div
            v-if="active"
            class="skill__content"
            v-popover.top="{name}"
        >
            <img class="skill__content-img" :src="image ? image : require('@/assets/img/icons/health.png')">

            <div class="skill__time">
                <img class="skill__time-clock" loading="lazy" src="@/assets/svg/clock.svg?inline" alt="clock">

                <div class="skill__time-progress">
                    <i class="skill__time-bg" :style="{width: `${progressPercent}%`}"></i>
                </div>
            </div>

            <div v-if="name" class="skill__name">{{name}}</div>

            <popover
                :name="name"
                :width="365"
                event="hover"
                class="skill__popover"
            >
                <div class="passive">
                    <h2 class="skill__popover-title">{{type}} SKILL</h2>

                    <div class="skill__popover-content">
                        <div class="skill__popover-text skill__popover-text--description">
                            {{description}}
                        </div>

                        <div class="skill__popover-text">
                            remaining time:
                        </div>

                        <div class="skill__popover-timer">
                            <staking-timer :time="duration"/>
                        </div>
                    </div>
                </div>
            </popover>
        </div>

        <div
            v-else
            class="skill__text skill__text--passive"
        >
            <span class="skill__text-block">+</span>
        </div>
    </div>
</template>

<script>
import StakingTimer from './staking-timer'

export default {
    name: 'Skill',
    components: {
        StakingTimer,
    },
    props: {
        active: { type: Boolean },
        name: { type: String, default: '' },
        description: { type: String, default: '' },
        duration: {type: Number, default: 0 },
        total_duration: {type: Number, default: 0 },
        type: {type: String, default: '', required: true},
        image: {type: String, default: ''},
    },
    computed: {
        progressPercent() {
            const remainingPercent = this.duration / this.total_duration * 100;

            return remainingPercent > 100 ? 100 : remainingPercent;
        },
    }
}
</script>

<style lang="scss" scoped>

.skill {
  max-width: 64px;
  z-index: 0;

    &--active {
        padding: 0;
    }

    &__content {
        &-img {
            width: 100%;
            height: 100%;
        }
    }

    &__name {
        font: 500 16px Tektur;
        color: #BFBFBF;
        word-break: break-word;
    }

    &__time {
        margin: 15px 0;
        display: flex;
        gap: 7px;

        &-clock {
            width: 12px;
            height: 12px;
        }
        &-progress {
            position: relative;
            width: 50px;
            height: 10px;
            background-color: #6B7B98;
            border-radius: 2px;
        }
        &-bg {
            position: absolute;
            background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
            height: 100%;
            border-radius: 2px;
            left: 0;
        }
    }

    &__popover {
        background-color: #CBD3E1;
        padding: 12px 18px;
        transform: skewX(-4deg);
        border-radius: 0;

        &-title {
            text-transform: uppercase;
            font: 800 24px Raleway;
            margin-bottom: 8px;
        }
        &-text {
            padding: 8px 0;
            font: 500 14px "Tektur";

            &--description {
                padding: 2px 0;
            }
        }
        &-timer {
            padding: 5px;
            background-color: white;
            transform: skewX(4deg);
            text-transform: uppercase;
        }
    }

  &.active {
    background-color: #000;

    &:before,
    &:after {
      display: none;
    }
  }
}

.skill__text {
    width: 64px;
    height: 64px;
    padding: 7px;
    background-color: #919EB7;

    &-block {
        cursor: pointer;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        text-transform: uppercase;
        line-height: 1.4;
        background-color: #6B7B98;
    }

    &--passive {
        color: #919EB7;
        font-size: 24px;
        font-weight: bold;
    }

    &--active {
        font-size: 12px;
        font-weight: 500;

        white-space: break-spaces;

        color: #fff;

        border: 2px solid;
    }
}

</style>
