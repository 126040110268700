var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "skill",
      class: { "skill--active": _vm.active },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.active
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover.top",
                  value: { name: _vm.name },
                  expression: "{name}",
                  modifiers: { top: true },
                },
              ],
              staticClass: "skill__content",
            },
            [
              _c("img", {
                staticClass: "skill__content-img",
                attrs: {
                  src: _vm.image
                    ? _vm.image
                    : require("@/assets/img/icons/health.png"),
                },
              }),
              _c("div", { staticClass: "skill__time" }, [
                _c("img", {
                  staticClass: "skill__time-clock",
                  attrs: {
                    loading: "lazy",
                    src: require("@/assets/svg/clock.svg?inline"),
                    alt: "clock",
                  },
                }),
                _c("div", { staticClass: "skill__time-progress" }, [
                  _c("i", {
                    staticClass: "skill__time-bg",
                    style: { width: `${_vm.progressPercent}%` },
                  }),
                ]),
              ]),
              _vm.name
                ? _c("div", { staticClass: "skill__name" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ])
                : _vm._e(),
              _c(
                "popover",
                {
                  staticClass: "skill__popover",
                  attrs: { name: _vm.name, width: 365, event: "hover" },
                },
                [
                  _c("div", { staticClass: "passive" }, [
                    _c("h2", { staticClass: "skill__popover-title" }, [
                      _vm._v(_vm._s(_vm.type) + " SKILL"),
                    ]),
                    _c("div", { staticClass: "skill__popover-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "skill__popover-text skill__popover-text--description",
                        },
                        [_vm._v(" " + _vm._s(_vm.description) + " ")]
                      ),
                      _c("div", { staticClass: "skill__popover-text" }, [
                        _vm._v(" remaining time: "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "skill__popover-timer" },
                        [
                          _c("staking-timer", {
                            attrs: { time: _vm.duration },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "skill__text skill__text--passive" }, [
            _c("span", { staticClass: "skill__text-block" }, [_vm._v("+")]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }