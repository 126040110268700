<template>
  <vue-countdown
      :time="+time * 1000"
      :transform="transform"
      v-slot="{ days, hours, minutes, seconds }"
      @end="$emit('end')"
  >
    <div class="staking-timer">
      <div class="staking-timer__item">
        <div class="staking-timer__number">{{ days }}</div>
        <div class="staking-timer__type">days</div>
      </div>

      <div class="staking-timer-separator">:</div>

      <div class="staking-timer__item">
        <div class="staking-timer__number">{{ hours }}</div>
        <div class="staking-timer__type">hrs</div>
      </div>

      <div class="staking-timer-separator">:</div>

      <div class="staking-timer__item">
        <div class="staking-timer__number">{{ minutes }}</div>
        <div class="staking-timer__type">min</div>
      </div>

      <div class="staking-timer-separator">:</div>

      <div class="staking-timer__item">
        <div class="staking-timer__number">{{ seconds }}</div>
        <div class="staking-timer__type">sec</div>
      </div>
    </div>
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
export default {
    name: "StakingTimer",
    components: {
        VueCountdown,
    },
    props: {
        time: { type: [String, Number], default: '' },
    },
    methods: {
        transform(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
              formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    }
}
</script>

<style lang="scss" scoped>

.staking-timer {
  display: flex;
  justify-content: center;
}

.staking-timer__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 5px;
}

.staking-timer__number {
  position: relative;
  font: 800 24px Raleway;
  font-variant-numeric: lining-nums;

  text-align: center;
  padding: 4px 3px;

  line-height: 28px;

  background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staking-timer-separator {
    font: 800 24px Raleway;
    line-height: 28px;
    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 3px;
}

.staking-timer__type {
  margin-top: 2px;
  font: 500 14px Tektur;
  color: #BFBFBF;
}

</style>
